import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Budget, BudgetMediaType, MediaType, TargetMediaType} from '../models';
import {targetKeys} from '@simplifi/shared/enums';
import {CreatePlanService} from '../services';

@Injectable()
export class BudgetMediaTypeAdapter implements IAdapter<BudgetMediaType> {
  constructor(public createPlanService: CreatePlanService) {}
  adaptToModel(resp: any): BudgetMediaType {
    return resp;
  }

  adaptFromModel(data: Budget): any {
    const payload: BudgetMediaType[] = [];
    data.targetMediaTypeMappings.forEach(mapping => {
      targetKeys.forEach(key => {
        const mediaType = mapping[key as keyof TargetMediaType] as MediaType;
        payload.push({
          planVersionId: data.planVersionId,
          targetMediaTypeId: mediaType.id,
          budgetAllocationActual: Number(mediaType.allocatedBudget),
          budgetAllocationDefault: Number(mediaType.defaultBudget),
          cpmActual: Number(mediaType.maxBid),
          cpmDefault: Number(mediaType.defaultMaxBid),
          recommendedPercentage: mapping.recommendedBudgetPercentange,
        });
      });
    });

    return {
      data: payload,
      recalculatedConfig: data.recalculatedConfig,
      planData: {
        budgetType: this.createPlanService.plan.budgetType,
        startDate: this.createPlanService.plan.startDate,
        endDate: this.createPlanService.plan.endDate,
        suggestedBudget: this.createPlanService.plan.budget.suggested.total,
      },
    };
  }
}
