import {Injectable} from '@angular/core';
import {IAdapter, UserSessionStoreService} from '@simplifi/core/index';
import {Chats} from '../models';
import {
  Numbers,
  multiOccupantBanner,
  singleOccupantBanner,
} from '@simplifi/shared/enums';

@Injectable()
export class ChatAdapterService implements IAdapter<Chats> {
  constructor(private readonly store: UserSessionStoreService) {}
  adaptToModel(resp: any): Chats {
    const response = new Chats(resp.channelId);
    response.occupants = resp?.occupants;
    response.banner =
      resp?.occupants === 1 ? singleOccupantBanner : multiOccupantBanner;
    response.metadata.currentQuestion = resp?.aiContext?.nextQuestion;
    response.metadata.previousResponse = resp?.aiContext?.currentResponse;
    return response;
  }
  adaptFromModel(data: Partial<Chats>) {
    return {
      body: data?.message?.text,
      channelId: data.id,
      tagEnabled: data.tagEnabled,
      enablePlanCreation: data.enablePlanCreation,
      metadata: data.metadata,
      currentDate: this.getLocalDateTimeISOFormat(),
    };
  }

  private getLocalDateTimeISOFormat(): string {
    const currentDate = new Date();

    // Format date components in one line
    const [year, month, day, hours, minutes, seconds, milliseconds] = [
      currentDate.getFullYear(),
      String(currentDate.getMonth() + 1).padStart(Numbers.numberTwo, '0'),
      String(currentDate.getDate()).padStart(Numbers.numberTwo, '0'),
      String(currentDate.getHours()).padStart(Numbers.numberTwo, '0'),
      String(currentDate.getMinutes()).padStart(Numbers.numberTwo, '0'),
      String(currentDate.getSeconds()).padStart(Numbers.numberTwo, '0'),
      String(currentDate.getMilliseconds()).padStart(Numbers.numberThree, '0'),
    ];

    // Calculate timezone offset in ±hh:mm format
    const offset = currentDate.getTimezoneOffset();
    const absOffsetHours = Math.floor(Math.abs(offset) / 60);
    const absOffsetMinutes = Math.abs(offset) % 60;
    const sign = offset <= 0 ? '+' : '-';
    const timezoneOffset = `${sign}${String(absOffsetHours).padStart(2, '0')}:${String(absOffsetMinutes).padStart(2, '0')}`;

    // Construct and return the ISO format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffset}`;
  }
}
