import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSessionStoreService} from '@simplifi/core/store';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';
import {CopyHelperService} from '@simplifi/shared/services';

@Component({
  selector: 'simplifi-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrl: './link-cell-renderer.component.scss',
})
export class LinkCellRendererComponent implements ICellRendererComponent {
  constructor(
    private router: Router,
    protected readonly route: ActivatedRoute,
    private readonly copyHelper: CopyHelperService,
    private readonly store: UserSessionStoreService,
  ) {}
  planName = '';
  planVersion = '';
  planId = '';
  planVersionId = '';
  currentPage = 'general';
  showRestoreIcon = false;
  agInit(params: ICellRenderer): void {
    this.planName = params?.data?.name;
    this.currentPage = params.data.currentPage;
    this.planVersion = params?.data?.version;
    this.planId = params?.data?.id;
    this.planVersionId = params?.data?.versionId;
    this.showRestoreIcon = params?.data?.child?.length > 1;
  }

  refresh() {
    return true;
  }

  navigateToPlan() {
    this.router.navigate(
      [`./create/${this.currentPage}/${this.planId}/${this.planVersionId}`],
      {relativeTo: this.route},
    );
  }

  navigatePlanner() {
    this.copyHelper.restoreVersion({
      id: this.planId,
      versionId: this.planVersionId,
      isPlanCopyOperation: false,
      version: this.planVersion,
      restore: true,
    });
  }
}
