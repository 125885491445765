import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BudgetReachPieType} from '../models';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
import {
  REACH_DATA_TYPE,
  SHOW_LABEL_FIELDS,
  SHOW_TOOLTIP_FIELDS,
} from '../../constants';
import {ReachData} from '../models/reach-data.model';

@Injectable()
export class BudgetReachService {
  // BehaviorSubject to store and emit the current reach data
  public reachDataSource = new BehaviorSubject<{
    strategy: PlanBudgetStrategy;
    data: BudgetReachPieType;
  } | null>(null);

  // Observable that components can subscribe to
  public reachData$ = this.reachDataSource.asObservable();

  constructor() {}

  updateReachData(
    strategy: PlanBudgetStrategy,
    data: BudgetReachPieType,
  ): void {
    this.reachDataSource.next({strategy, data});
  }

  /**
   * The function `updateChartFlags` iterates through reach data types and hides tooltips and labels
   * for specific reach types.
   * @param respStrategy - The `respStrategy` parameter is of type `ReachData[PlanBudgetStrategy]`. It
   * seems to be an object containing data related to budget strategies for reaching a target audience.
   * The function `updateChartFlags` iterates over this data and updates the `showTooltip` and
   * `showLabel`
   */
  updateChartFlags(respStrategy: ReachData[PlanBudgetStrategy]) {
    REACH_DATA_TYPE.forEach(key => {
      const chartObj = respStrategy[key as 'household' | 'population'];
      chartObj.forEach(reachType => {
        if (SHOW_TOOLTIP_FIELDS.includes(reachType.name)) {
          reachType.showTooltip = true;
        }
        if (SHOW_LABEL_FIELDS.includes(reachType.name)) {
          reachType.showLabel = true;
        }
      });
    });
  }
}
