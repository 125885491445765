import {Component} from '@angular/core';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';

@Component({
  selector: 'simplifi-plan-budget-cell-renderer',
  templateUrl: './plan-budget-cell-renderer.component.html',
  styleUrl: './plan-budget-cell-renderer.component.scss',
})
export class PlanBudgetCellRendererComponent implements ICellRendererComponent {
  constructor() {}
  totalBudget = '';
  agInit(params: ICellRenderer): void {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    this.totalBudget = currencyFormatter.format(
      params?.data?.budget[PlanBudgetStrategy.Max].total,
    );
  }

  refresh() {
    return true;
  }
}
