// chart-config.service.ts
import {Injectable} from '@angular/core';
import * as Highcharts from 'highcharts';
import {HighChartsPieDataset} from '../models';

@Injectable()
export class ChartConfigService {
  /**
   * Get base pie chart configuration
   * @param title Optional chart title
   * @param innerSize Percentage of inner pie size (donut effect)
   */
  getPieChartBaseConfig(
    pointConfig: {[key: string]: HighChartsPieDataset},
    title = '',
    innerSize = '30%',
  ): Partial<Highcharts.Options> {
    return {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        animation: true,
      },

      title: {
        text: title,
      },
      plotOptions: {
        pie: {
          innerSize: innerSize,
          allowPointSelect: false,
          slicedOffset: 0,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 2,
            connectorWidth: 0,

            style: {
              whiteSpace: 'nowrap',
              overflow: 'visible',
              textOverflow: 'none',
            },
            formatter: function () {
              const config = pointConfig[this.point.name];
              if (config?.showLabel) {
                return `${Highcharts.numberFormat(this.percentage, 0)}%`;
              }
              return null;
            },
          },
          states: {
            hover: {enabled: true, halo: null},
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        formatter: function () {
          const config = pointConfig[this.point.name];
          if (config?.showTooltip) {
            return `<b>${this.point.name}</b>: ${config.labelValue}`;
          }
          return false;
        },
      },
    };
  }

  /**
   * Customize additional chart options if needed
   */
  customizePieChartOptions(
    baseConfig: Partial<Highcharts.Options>,
    customOptions: Partial<Highcharts.Options>,
  ): Partial<Highcharts.Options> {
    return {...baseConfig, ...customOptions};
  }
}
