import {OperatorFilter} from './operator-filter.model';

export class PlanFilter {
  status!: string[];
  targets!: string[];
  totalBudget!: OperatorFilter;
  versionCreatedOn!: OperatorFilter;
  goalName!: string[];
  constructor() {
    this.totalBudget = new OperatorFilter();
    this.versionCreatedOn = new OperatorFilter();
  }
}
