import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SwitchRoleService {
  private readonly switchRoleSubject = new Subject<{
    id: string;
    isChecked: boolean;
  }>();
  switchRoleData = this.switchRoleSubject.asObservable();

  public setData(switchRoleData: {id: string; isChecked: boolean}) {
    this.switchRoleSubject.next(switchRoleData);
  }
}
