import {Component} from '@angular/core';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';

@Component({
  selector: 'simplifi-plan-id-cell-renderer',
  templateUrl: './plan-id-cell-renderer.component.html',
  styleUrl: './plan-id-cell-renderer.component.scss',
})
export class PlanIdCellRendererComponent implements ICellRendererComponent {
  constructor() {}
  planName = '';
  planId = '';
  isParentPlanVersion = false;
  agInit(params: ICellRenderer): void {
    this.planName = params?.data?.name;
    this.planId = params?.data?.id;
    this.isParentPlanVersion = params?.data?.child?.length === 1;
  }

  refresh() {
    return true;
  }
}
