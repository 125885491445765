import {NgxMaskConfig} from 'ngx-mask';

export const maskConfig: Partial<NgxMaskConfig> = {
  validation: false,
  thousandSeparator: ',', // Default separator for thousands
  decimalMarker: '.', // Default marker for decimals
};

export const ConfirmationWindow = {
  maxWidth: '450px',
  width: '100%',
};
