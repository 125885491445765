import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {PlanConfiguration} from '@simplifi/shared/models/plan-configurations.model';

@Injectable()
export class PlanConfigurationHelperService {
  private readonly planConfigurationSubject =
    new BehaviorSubject<PlanConfiguration>(new PlanConfiguration());
  planConfiguration$ = this.planConfigurationSubject.asObservable();
  public editPlanConfiguration(data: PlanConfiguration) {
    this.planConfigurationSubject.next(data);
  }

  private readonly freqUpdateSubject = new Subject<boolean>();
  freqUpdate$ = this.freqUpdateSubject.asObservable();
  public updatePlanConfiguration(data: boolean) {
    this.freqUpdateSubject.next(data);
  }
}
