export class HighChartsPieDataset {
  name: string;
  value: number;
  labelValue: number;
  color: string;
  showLabel: boolean;
  showTooltip: boolean;
  constructor() {
    this.name = '';
    this.value = 0;
    this.labelValue = 0;
    this.color = '#2B72FB';
    this.showLabel = false;
    this.showTooltip = false;
  }
}
