import {Plan} from '@simplifi/main/planner/create-plan/models';

export class PlanView extends Plan {
  createdOn!: string;
  goalAmount!: number;
  versionId!: string;
  planId!: string;
  version!: string;
  child!: string[];
  goalName!: string;
  currentPage = true;
  editForm!: boolean;
  targetConfig!: ITargetConfig;
}

export interface ITargetConfig {
  keyword?: boolean;
  addressable?: boolean;
  contextual?: boolean;
  ztv?: boolean;
}
