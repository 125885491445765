import {AiBotName} from '@simplifi/core/constants';

export const Messages = {
  successMsg: 'Success!',
  warningMsg: 'Warning!',
  infoMsg: 'Information!',
  tenantRegistered:
    'Organization admin has been created successfully, please login.',
  deletePlanMessage: 'Are you sure, you want to delete the plan(s)?',
  deleteAllPlansMessage: 'Are you sure, you want to delete all the plans?',
  deletePlanSuccessMessage: 'Plan(s) deleted successfully.',
  deleteOrganization: `Are you sure you want to delete this organization? Deleting it will also remove all users associated with it.`,
  saveCreatePlanChanges: `Are you sure you want to save the data?`,
  switchOrganization: `Do you really want to switch this organization?`,
  tenantSwitchedSuccessfully: `Tenant switched successfully.`,
  orgDeletedSuccessFully: `Organization deleted successfully.`,
  userDeletedSuccessFully: `Organization user deleted successfully.`,
  foundationUserMadeSuccessFully: `Foundation user role has been assigned to the selected user successfully.`,
  foundationUserRevertedSuccessFully: `Foundation user role has been removed to the selected user successfully.`,

  userInvitedSuccessFully: `Invitation sent successfully.`,
  deleteUserMessage: 'Are you sure, you want to delete the user(s)?',
  makeFoundationUserMessage:
    'Are you sure, you want to make this user foundation user?',
  revertFoundationUserMessage:
    'Are you sure, you want to revert the foundation user role?',
  deleteAllUsersMessage: 'Are you sure, you want to delete all the users?',
  deleteUserSuccessMessage: 'User(s) deleted successfully.',
  deleteUserExceptOneWithDefaultOrgSuccessMessage:
    'User(s) deleted successfully except those which has the current organization as default organization.',
  planCreateSuccessMessage: 'Plan details saved successfully.',
  planEditSuccessMessage: 'Plan details updated successfully.',
  locationChange:
    'Changing selection will delete all previously selected locations.',
  budgetUpdated: 'Plan budget updated successfully.',
  changesNeedsToBeSave:
    'Please ensure that plan changes are saved before proceeding.',
  createPlanMandatoryDisableToolTip:
    'Please ensure all mandatory fields are filled.',
  createPlanTargetMandatoryDisableToolTip:
    'Please add atleast one target details.',
  createPlanBudgetMandatoryDisableToolTip:
    'Please make sure allocated budget should equal to total budget.',
  resetKeywordText: 'Are you sure you want to clear the keywords data?',
  addressableKeywordText:
    'Are you sure you want to clear the addressable data?',
  resetZtvZipCodeText: 'Are you sure you want to clear the zip code data?',
  resetContextualText:
    'Are you sure you want to clear the third party segments data?',
  keywordWarningText:
    'Data added in suggestion box will be wiped off. Are you sure you want to continue?',
  cancelProcess:
    'Are you sure you want to stop the process of saving the details?',
  noZipCodeFound: 'No zip code found with given search criteria.',
  deleteKeywordText: 'Are you sure you want to remove the keyword(s)?',
  deleteZtvZipCodeText: 'Are you sure you want to remove the zip code(s)?',
  deleteContextualText:
    'Are you sure you want to remove the third party segment(s)?',
  deleteAddressableText: 'Are you sure you want to remove the demographic(s)?',
  deleteLocationsText: 'Are you sure you want to remove the location(s)?',
  deleteKeywordSuccess: 'Keyword(s) removed successfully!',
  deleteZtvZipCodeSuccess: 'Zip Adviser(s) removed successfully!',
  deleteContextualSuccess: 'Third party segment(s) removed successfully!',
  deleteAddressableSuccess: 'Demographic(s) removed successfully!',
  formValidationFailed: `Form validation failed.`,
  validationFailed: `Validation Failed.`,
  detailsSavedSuccessFully: `Details saved successfully.`,
  success: `Success`,
  invalidEmailFormat: `Please enter valid format of the email address example(john@gmail.com)`,
  planCopySuccess: `New copy of the plan is created successfully with name: `,
  planVersionUpdateSuccess: `Version created successfully.`,
  planCopyConfirmation: `Are you sure you want to create a copy of the plan ?`,
  versionRestoredSuccess: `Version restored successfully.`,
  phoneNoValidation: `Phone number is a numeric field and should be 10 digit.`,
  companyNameValidation: `Company name is alphanumeric and cannot be more than 250 characters.`,
  companyNameFreeTextValidation: `Company name cannot be more than 250 characters.`,

  CompanyAddressValidation: `Company Address cannot be more than 250 characters.`,
  urlValidation: `Please enter valid format of the URL(www.online.com, http://www.example.com).`,
  timeframeValidation: 'Timeframe is mandatory.',
  userNameValidation: `Name should be alphabetic with min 3 alphabets and max 250 alphabets.`,
  planNameValidation: `Plan name must be alphanumeric, with a min of 4 characters and a max of 250 characters.`,
  contentCategoryRequired: `Category is mandatory.`,
  deviceTypeRequired: `Device type is mandatory.`,
  planGoalRequired: `Plan goal is mandatory.`,
  budgetValidation: `Budget value is numeric.`,
  ztvValidation: `Please provide a comma-separated list of valid 5-digit ZIP codes, with a min of 1 and a max of 50 ZIP codes.`,
  phoneNoSimpliFiValidation: `Phone number should follow (XXX) XXX-XXXX.`,
  phonePatternSimpliFiMessage: `Phone number should be consist of 10 digits.`,
  singleOccupantMessage: `All messages will be directed to ${AiBotName} since you're a single user interacting with the bot`,
  multiOccupantMessage: `Since there are multiple users chatting, to ensure efficient communication, please use @${AiBotName} before your message to direct to ${AiBotName}. This will assist you promptly and effectively.`,
  removeAllBudgetMessage:
    'Are you sure you want to remove all the items in budget allocation ?',
  removeAllBudgetSuccess:
    'All the budget allocation details are removed successfully.',
  noContextMessage: 'No data filled yet',
  locationRemovalSuccessMessage: 'Location removed successfully.',
  alllocationsRemovalSuccessMessage: 'All locations removed successfully.',
  unsavedChanges: 'You have unsaved changes. Do you want to save them?',
  discardChanges:
    'Some data in the form is incorrect. If you proceed, all the changes will be lost. Do you want to continue?',
  unsaveWarning: 'Please save the data to proceed further!',
  resetBudget: 'Are you sure you want to reset the values to default values?',
  resetBudgetSuccess: 'Budget values reset successfully to default values',
  downloadRequestNotification:
    'A notification will be sent when the file is ready for download.',
  errorDownloadPlanTargetFile:
    'An error occurred while downloading the plan target data.',
  goalDeleteConfirmation: `Are you sure you want to delete the goal type?`,
  promptDeleteConfirmation: `Are you sure you want to delete the prompt?`,
  resetPromptConfirmation: `Are you sure you want to reset the prompt to it's initial version value?`,
  markGoalInactiveConfirmation: `Are you sure, you want to mark the goal type as active/inactive?`,
  goalDeleteSuccessMsg: `Goal type deleted successfully.`,
  promptDeleteSuccessMsg: `Prompt deleted successfully.`,
  goalDeleteAssociatedConfirmation:
    'Goal type is associated with any of the plans, you cannot delete it, but you can mark the status of the goal type as inactive.',
  tierDeleteConfirmation: `Are you sure you want to delete the tier?`,
  tierRangeValidation: `Tier range should be a number, and it be less than range 'to'.`,
  tierRangeFromValidation: `Tier range 'from' should be a number and should be less than range 'to'.`,
  tierRangeToValidation: `Tier range 'to' should be a number and it should be greater than range 'from'.`,
  tierNameRequired: `Tier name is required.`,
  decimalPatternValidationError: `Only whole number OR decimal values with format 0.00 are allowed.`,
  percentPatternValidationError: `Values from 0.00-100.00 are allowed only.`,
  aiGeneralMessage:
    'I have copied over the details from our conversation. Feel free to review for accuracy and then we can proceed to the next step',
  aiTargetMessage:
    'Based on your brand and goal, I have selected the following targeting strategies for you based off of best practices according to your business vertical. Feel free to adjust any of these if you would like before we move into some budget allocation recommendations',
  aiBudgetMessage:
    'Based on your targeting selections and goal, I believe the following breakout will be most effective for you. You can adjust these numbers as you see fit. I have also included a maximum number which represents the total avails number',
  contentCategoryValidation: 'You have selected maximum content categories.',
  switchRoleLogoutMessage: `Your user role has been changed. Login again to access the application.`,
  userManagementListUpdated: `This page has been updated.`,
  noNetworkMessage: 'No Internet connection, please connect to internet',
  recalculateBudgetConfirmation: `Please select the action to be performed for budget.\n\nNote: "Recalculate Budget" will adjust the budget based on tier recommendations.`,
  proceedConfirmation: `Are you sure you want to log out? Any unsaved changes may be lost.`,
  confirmGoalCancellation: 'Are you sure you want to cancel?',
};
