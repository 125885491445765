import {ApiService, IAdapter, PatchAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';

export class RecalculateBudgetCommand<T> extends PatchAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    planId: string,
    versionId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${versionId}/suggested-budget`,
    );
  }
}
