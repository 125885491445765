import {Component} from '@angular/core';
import {PlanTargetIcon} from '@simplifi/shared/enums';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';

@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './plan-target-cell-renderer.component.html',
  styleUrls: ['./plan-target-cell-renderer.component.scss'],
})
export class PlanTargetCellRendererComponent implements ICellRendererComponent {
  constructor() {}
  icons: string[] = [];
  ztvImagePath = '';
  agInit(params: ICellRenderer): void {
    this.icons.push(
      params?.data?.hasAddressable ? PlanTargetIcon.Addressable : '',
    );
    this.icons.push(
      params?.data?.hasContextual ? PlanTargetIcon.Contextual : '',
    );
    this.icons.push(params?.data?.hasKeywords ? PlanTargetIcon.Search : '');
    this.ztvImagePath = params?.data?.hasZtv ? PlanTargetIcon.Ztv : '';
  }

  refresh() {
    return true;
  }
}
