<div class="flex items-center gap-x-2">
  <span class="version-name" matTooltip="{{ planVersion }}">
    <a class="no-underline" (click)="navigateToPlan()" [attr.href]="null">{{
      planVersion
    }}</a>
  </span>
  <a
    aria-label="Version Restore"
    class="no-underline version-restore"
    *ngIf="showRestoreIcon"
    matTooltip="Version Restore"
  >
    <button (click)="navigatePlanner()">
      <i class="el-icon-refresh"></i>
    </button>
  </a>
</div>
