<div class="filter-card el-card is-always-shadow">
  <div class="el-card__body">
    <div class="data-filter">
      <form class="el-form data-filter-form" #form="ngForm">
        <form class="el-form els-form--compact el-form--label-left">
          <div class="el-row filter-row flex items-center">
            <div class="el-col el-col-8">
              <label class="el-form-item__label flex" for="status">
                Status
              </label>
            </div>
            <div class="el-col el-col-16">
              <simplifi-select-box
                [items]="statusOptions"
                [placeholder]="'Select Filter'"
                [multiple]="true"
                [inputLabel]="false"
                [optionsWithIcons]="true"
                [(selectModel)]="tempPlanFilter.status"
                (selectModelChange)="updateFilterCount()"
                [name]="'status'"
              ></simplifi-select-box>
            </div>
          </div>
          <div class="el-row filter-row flex items-center">
            <div class="el-col el-col-8">
              <label class="el-form-item__label flex" for="status">
                Targeting
              </label>
            </div>
            <div class="el-col el-col-16">
              <simplifi-select-box
                [inputLabel]="false"
                [placeholder]="'Select Filter'"
                [multiple]="true"
                [(selectModel)]="tempPlanFilter.targets"
                (selectModelChange)="updateFilterCount()"
                [items]="targetOptions"
                [name]="'target'"
              ></simplifi-select-box>
            </div>
          </div>
          <div class="el-row filter-row flex items-center">
            <div class="el-col el-col-8">
              <label class="el-form-item__label flex" for="status">
                Total Budget
              </label>
            </div>
            <div class="el-col el-col-16 flex gap-x-2 items-center">
              <simplifi-select-box
                class="min-120"
                [items]="budgetOptions"
                [(selectModel)]="tempPlanFilter.totalBudget.operator"
                [placeholder]="'Select Filter'"
                [inputLabel]="false"
                (selectModelChange)="updateFilterCount()"
                [name]="'budget'"
              ></simplifi-select-box>
              <simplifi-input-box
                class="flex-1"
                type="text"
                [inputLabel]="false"
                [disabled]="!tempPlanFilter.totalBudget.operator"
                [(inputModel)]="budgetFilter.startingBudget"
                (inputModelChange)="updateFilterCount()"
                [prefixText]="'$'"
                [name]="'startingBudget'"
                [maxlength]="15"
                [pattern]="pattern.numberOnlyPattern"
                [mask]="'separator.2'"
              ></simplifi-input-box>
              <span *ngIf="tempPlanFilter.totalBudget.operator === between"
                >to</span
              >
              <simplifi-input-box
                *ngIf="tempPlanFilter.totalBudget.operator === between"
                class="flex-1"
                type="text"
                [inputLabel]="false"
                [disabled]="!tempPlanFilter.totalBudget.operator"
                [(inputModel)]="budgetFilter.endingBudget"
                (inputModelChange)="updateFilterCount()"
                [prefixText]="'$'"
                [name]="'endingBudget'"
                [maxlength]="15"
                [pattern]="pattern.numberOnlyPattern"
                [mask]="'separator.2'"
              ></simplifi-input-box>
            </div>
          </div>
          <div class="el-row filter-row flex items-center">
            <div class="el-col el-col-8">
              <label class="el-form-item__label flex" for="status">
                Date Created
              </label>
            </div>
            <div class="el-col el-col-16 flex gap-x-2">
              <simplifi-select-box
                [items]="dateOptions"
                class="min-120"
                [(selectModel)]="tempPlanFilter.versionCreatedOn.operator"
                (selectModelChange)="updateDates()"
                [placeholder]="'Select Filter'"
                [inputLabel]="false"
                [name]="'date'"
              ></simplifi-select-box>
              <simplifi-date-range
                class="flex-1 data-date-filter"
                id="timeframe"
                [inputLabel]="false"
                prefixIcon="el-icon-date"
                [required]="true"
                [singleDatePicker]="
                  !(tempPlanFilter.versionCreatedOn.operator === between)
                "
                [disabled]="!tempPlanFilter.versionCreatedOn.operator"
                [(selected)]="selectedDate"
                (selectedChange)="updateFilterCount()"
              ></simplifi-date-range>
            </div>
          </div>
          <div class="el-row filter-row flex items-center">
            <div class="el-col el-col-8">
              <label class="el-form-item__label flex" for="status">
                Goal
              </label>
            </div>
            <div class="el-col el-col-16">
              <simplifi-select-box
                [multiple]="true"
                [inputLabel]="false"
                [placeholder]="'Select Filter'"
                [items]="goal"
                [(selectModel)]="tempPlanFilter.goalName"
                (selectModelChange)="updateFilterCount()"
                [name]="'goal'"
              ></simplifi-select-box>
            </div>
          </div>
          <div
            class="el-row filter-action el-row--flex is-justify-space-between gap-x-2 pt-2"
          >
            <simplifi-input-button
              type="basic"
              color="dangerText"
              [name]="'Clear Filter'"
              leftIcon="el-icon-circle-close-fill"
              (clickButton)="clearFilters()"
            ></simplifi-input-button>
            <simplifi-input-button
              type="basic"
              color="primary"
              [name]="'Apply'"
              (clickButton)="applyFilters()"
            ></simplifi-input-button>
          </div>
        </form>
      </form>
    </div>
  </div>
</div>
