import {Component} from '@angular/core';
import {PlanStatus, statusIconMap} from '@simplifi/shared/enums';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';
const ICON_DIRECTORY = '/assets/images/planner/list/';

@Component({
  selector: 'simplifi-plan-status-cell-renderer',
  templateUrl: './plan-status-cell-renderer.component.html',
  styleUrls: ['./plan-status-cell-renderer.component.scss'],
})
export class PlanStatusCellRendererComponent implements ICellRendererComponent {
  constructor() {}
  planName = '';
  iconClass = '';
  imagePath = '';
  isParentPlanVersion = false;
  statusText = '';
  agInit(params: ICellRenderer): void {
    this.planName = params?.data?.name;
    this.isParentPlanVersion = params?.data?.child?.length === 1;
    const status = params?.data?.status;
    this.statusText = this.getStatusText(status);

    switch (params?.data?.status) {
      case PlanStatus.Draft:
        this.iconClass = 'el-icon-status-draft status-draft';
        break;
      case PlanStatus.Complete:
        this.imagePath = `${ICON_DIRECTORY}${statusIconMap.get(params?.data?.status)}`;
        break;
      case PlanStatus.Downloaded:
        this.imagePath = `${ICON_DIRECTORY}${statusIconMap.get(params?.data?.status)}`;
        break;
      case PlanStatus.Error:
        this.iconClass = 'el-icon-status-error status-error';
        break;
      case PlanStatus.Expired:
        this.iconClass = 'el-icon-status-ended els-icon--regular';
        break;
      case PlanStatus.Processing:
        this.iconClass = 'el-icon-loading status-processing';
        break;
    }
  }

  getStatusText(status: PlanStatus): string {
    const statusTextMap: {[key in PlanStatus]: string} = {
      [PlanStatus.Draft]: 'Draft',
      [PlanStatus.Complete]: 'Complete',
      [PlanStatus.Downloaded]: 'Downloaded',
      [PlanStatus.Error]: 'Error',
      [PlanStatus.Expired]: 'Expired',
      [PlanStatus.Processing]: 'Processing',
    };

    return statusTextMap[status] || '';
  }

  refresh() {
    return true;
  }
}
