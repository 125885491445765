import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[simplifiChatSizeAdjust]',
})
export class ChatSizeAdjustDirective implements AfterViewInit, OnDestroy {
  private scrollableElement!: HTMLElement;
  private readonly resizeObserver: ResizeObserver;
  private readonly mutationObserver: MutationObserver;
  private routerEventsSubscription: Subscription | null = null;
  private initialParentHeight = 0;
  private initialScrollableHeight = 0;
  private previousHeight: string | null = null;
  private static readonly MIN_PARENT_HEIGHT = 67;
  private static readonly DEFAULT_PARENT_HEIGHT = 369;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
  ) {
    this.resizeObserver = new ResizeObserver(() => this.onResize());
    this.mutationObserver = new MutationObserver(() => this.onMutation());
  }

  ngAfterViewInit() {
    this.scrollableElement = this.el.nativeElement.querySelector(
      '#chatScroll',
    ) as HTMLElement;

    // Calculate and set initial heights after view initializes
    setTimeout(() => {
      this.calculateInitialHeights();
      this.setInitialHeight();
    });

    // Observe size changes in the element
    this.resizeObserver.observe(this.el.nativeElement);

    // Observe DOM mutations for AI thinking state changes
    this.mutationObserver.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });

    // Listen for route changes to recalculate heights
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (!this.initialParentHeight || !this.initialScrollableHeight) {
            this.calculateInitialHeights();
            this.setInitialHeight();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    // Cleanup observers and subscriptions on destroy
    this.resizeObserver.disconnect();
    this.mutationObserver.disconnect();
    this.routerEventsSubscription?.unsubscribe();
  }

  // Calculate initial heights of parent and scrollable elements
  private calculateInitialHeights() {
    this.initialParentHeight = this.el.nativeElement.clientHeight;
    this.initialScrollableHeight = this.scrollableElement.clientHeight;
  }

  // Set initial height of scrollable element
  private setInitialHeight() {
    this.renderer.setStyle(
      this.scrollableElement,
      'height',
      `${this.initialScrollableHeight}px`,
    );
  }

  // Adjust height dynamically when element resizes
  private onResize() {
    if (!this.el.nativeElement.querySelector('#aiThinking')) {
      this.adjustHeight();
    }
  }

  // Handle DOM mutations related to AI thinking state
  private onMutation() {
    if (this.el.nativeElement.querySelector('#aiThinking')) {
      this.handleAiThinking();
    } else {
      this.restoreHeightAndRecalculate();
    }
  }

  // Adjust height based on parent height changes
  private adjustHeight() {
    if (!this.scrollableElement || !this.initialParentHeight) return;
    if (
      this.initialParentHeight === ChatSizeAdjustDirective.MIN_PARENT_HEIGHT
    ) {
      this.initialParentHeight = ChatSizeAdjustDirective.DEFAULT_PARENT_HEIGHT;
    }

    const heightDifference =
      this.el.nativeElement.clientHeight - this.initialParentHeight;
    const adjustedHeight = this.initialScrollableHeight + heightDifference;

    this.renderer.setStyle(
      this.scrollableElement,
      'height',
      `${adjustedHeight}px`,
    );
  }

  // Handle AI thinking state by setting height to auto
  private handleAiThinking() {
    if (!this.previousHeight) {
      this.previousHeight =
        this.scrollableElement.style.height ||
        `${this.scrollableElement.clientHeight}px`;
    }
    this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
    this.renderer.setStyle(this.el.nativeElement, 'resize', 'none');
  }

  // Restore previous height when AI thinking state is removed
  private restoreHeightAndRecalculate() {
    if (!this.previousHeight) return;

    // Restore previous height
    this.renderer.setStyle(
      this.scrollableElement,
      'height',
      this.previousHeight,
    );

    // Reset previousHeight only after restoring
    this.previousHeight = null;

    // Allow resizing again
    this.renderer.setStyle(this.el.nativeElement, 'resize', 'vertical');

    setTimeout(() => {
      this.calculateInitialHeights();
      this.adjustHeight();
    });
  }
}
