import {Injectable} from '@angular/core';
import {UserSessionStoreService} from '@simplifi/core/store';
import PubNub from 'pubnub';
import {fromEvent, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SnackBarComponent} from '../components';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {CONNECTION_LOST_BANNER} from '@simplifi/main/planner/constants';
import {Numbers} from '../enums';

@Injectable({
  providedIn: 'root',
})
export class PubnubService {
  pubnub!: PubNub;
  private networkStatus$: Observable<boolean>;
  private channelGroups: string[] = [];
  snackBarRef!: MatSnackBarRef<SnackBarComponent>;
  constructor(
    private readonly store: UserSessionStoreService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.networkStatus$ = merge(
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)),
    );
    this.networkStatus$.subscribe(isOnline => {
      if (!isOnline) {
        this.snackBarRef = this.snackBar.openFromComponent(
          SnackBarComponent,
          CONNECTION_LOST_BANNER,
        );
      } else {
        this.snackBarRef._dismissAfter(Numbers.fiveHundred);
        this.handleReconnection();
      }
    });
  }

  initPubnub() {
    this.pubnub = new PubNub({
      subscribeKey: this.store.getPubnubKeys()?.subscribe,
      uuid: `${this.store.getUser().id}`,
      presenceTimeout: 40,
      heartbeatInterval: 30,
      restore: true,
      keepAlive: true,
    });

    this.pubnub.setToken(this.store.getPubnubKeys().token);
    this.channelGroups = [
      `user_${this.store.getUser()?.defaultTenantId}_${this.store.getUser().id}`,
    ];

    this.subscribeToChannelGroups();
  }

  private subscribeToChannelGroups() {
    if (this.channelGroups.length > 0) {
      setTimeout(() => {
        this.pubnub.subscribe({
          channelGroups: this.channelGroups,
        });
      }, Numbers.thousand);
    }
  }

  private handleReconnection() {
    if (this.pubnub) {
      this.pubnub.reconnect();
      this.subscribeToChannelGroups();
    } else {
      this.initPubnub();
    }
  }

  unsubscribeChannel(channel: string) {
    this.pubnub.unsubscribe({
      channels: [channel],
    });
  }

  unsubscribeAllChannels() {
    this.pubnub.unsubscribeAll();
  }
}
