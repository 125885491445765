import {GroupCellRenderer} from 'ag-grid-enterprise';
import {
  PlanStatusCellRendererComponent,
  PlanTargetCellRendererComponent,
  PlanNameCellRendererComponent,
  PlanBudgetCellRendererComponent,
  PlanGoalCellRendererComponent,
  PlanContextMenuRendererComponent,
  LinkCellRendererComponent,
} from '../components';
import {
  IDefaultColumnDefinition,
  IGridColumn,
  IGridOptions,
} from '@simplifi/shared/modules';
import {PlanIdCellRendererComponent} from '../components/cell-renderers/plan-id-cell-renderer/plan-id-cell-renderer.component';
const backgroundClass = 'grey-background';
export const COL_DEFS: IGridColumn[] = [
  {
    field: 'checkBox',
    headerName: '',
    headerCheckboxSelection: true,
    minWidth: 50,
    maxWidth: 50,
    checkboxSelection: true,
  },
  {
    field: 'expand',
    headerName: '',
    minWidth: 40,
    maxWidth: 40,
    cellRenderer: GroupCellRenderer,
    headerClass: backgroundClass,
  },
  {
    field: 'status',
    headerName: '',
    minWidth: 70,
    maxWidth: 70,
    cellRenderer: PlanStatusCellRendererComponent,
  },
  {
    field: 'id',
    headerName: 'ID',
    headerClass: backgroundClass,
    cellRenderer: PlanIdCellRendererComponent,
    minWidth: 170,
    allowSorting: false,
  },
  {
    field: 'name',
    headerName: 'NAME',
    headerClass: backgroundClass,
    cellRenderer: PlanNameCellRendererComponent,
    minWidth: 175,
    allowSorting: false,
  },
  {
    field: 'version',
    headerName: 'VERSION',
    headerClass: backgroundClass,
    cellRenderer: LinkCellRendererComponent,
    minWidth: 150,
    allowSorting: false,
  },
  {
    headerClass: backgroundClass,
    field: 'targeting',
    headerName: 'TARGETING',
    cellRenderer: PlanTargetCellRendererComponent,
    minWidth: 120,
    allowSorting: false,
  },
  {
    field: 'totalBudget',
    headerName: 'TOTAL BUDGET',
    headerClass: backgroundClass,
    cellRenderer: PlanBudgetCellRendererComponent,
    minWidth: 140,
    allowSorting: false,
  },
  {
    field: 'createdOn',
    headerName: 'DATE CREATED',
    headerClass: backgroundClass,
    minWidth: 100,
    allowSorting: false,
  },
  {
    field: 'goalAmount',
    headerName: 'GOAL',
    headerClass: backgroundClass,
    minWidth: 150,
    cellRenderer: PlanGoalCellRendererComponent,
    allowSorting: false,
  },
  {
    field: 'menu',
    headerName: '',
    headerClass: backgroundClass,
    minWidth: 50,
    maxWidth: 50,
    cellRenderer: PlanContextMenuRendererComponent,
    cellStyle: {justifyContent: 'center'},
  },
];

export const GRID_OPTIONS: IGridOptions = {
  suppressHeaderFocus: true,
  headerHeight: 30,
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 100,
  },
  getDataPath: data => data.child,
  autoGroupColumnDef: {
    maxWidth: 0,
  },
  suppressRowClickSelection: true,
  treeData: true,
  isRowSelectable: rowNode => !!rowNode?.data?.isCurrentVersion,
};

export const DEFAULT_COL_DEF: IDefaultColumnDefinition = {
  sortable: true,
  cellClass: 'number-cell, grid-cell-centered',
  editable: false,
  flex: 1,
  suppressMovable: true,
  suppressHeaderContextMenu: true,
  suppressHeaderMenuButton: true,
  resizable: false,
};
