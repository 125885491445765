import {HighChartsPieDataset} from '.';

export class BudgetReachPieType {
  household!: HighChartsPieDataset[];
  population!: HighChartsPieDataset[];
  constructor() {
    this.household = [new HighChartsPieDataset()];
    this.population = [new HighChartsPieDataset()];
  }
}
