import {Injectable} from '@angular/core';
import {
  DeletePlanContextualKeywords,
  GetContextual,
  GetPlanContextual,
} from '../commands';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {Observable, Subject} from 'rxjs';
import {Words} from '../interfaces';
import {UserSessionStoreService} from '@simplifi/core/store';
import {ContextualAdapter} from '../adapters';
import {HttpParams} from '@angular/common/http';

/**
 * Service for managing contextual keywords associated with a plan version.
 */
@Injectable()
export class ContextualFacadeService {
  tenantId: string;
  selectModelChange = new Subject<{
    id: string;
    selected: boolean;
  }>();
  searchText = '';

  /**
   * Constructs an instance of `ContextualFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling API responses.
   * @param {UserSessionStoreService} store - The user session store service.
   * @param {ContextualAdapter} contextualAdapter - The adapter for handling contextual data.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
    private readonly contextualAdapter: ContextualAdapter,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * Retrieves contextual keywords associated with a specific plan and version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of contextual keywords.
   */
  getPlanContextual(planId: string, versionId: string): Observable<Words[]> {
    const command: GetPlanContextual<Words[]> = new GetPlanContextual(
      this.apiService,
      this.anyAdapter,
      planId,
      versionId,
      this.store.getUser().defaultTenantId,
    );
    if (this.store.getStateSession()) {
      command.parameters = {
        query: new HttpParams().set(
          'stateSessionId',
          this.store.getStateSession(),
        ),
      };
    }
    return command.execute();
  }

  /**
   * Retrieves contextual keywords based on user input and plan/version IDs.
   *
   * @param {string} tenantId - The tenant ID for the request.
   * @param {string} body - The user input for contextual keywords.
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of contextual keywords.
   */
  getContextual(
    tenantId: string,
    body: string,
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    const command: GetContextual<Words[]> = new GetContextual(
      this.apiService,
      this.anyAdapter,
      tenantId,
      planId,
      versionId,
    );
    command.parameters = {
      data: {
        userInput: body.trim(),
        stateSessionId: this.store.getStateSession(),
      },
    };
    return command.execute();
  }

  /**
   * Deletes specific contextual keywords or all contextual keywords for a given plan and version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   * @param {Words} contextual - The contextual keyword to delete.
   *
   * @returns {Observable<void>} - An observable that completes when the contextual keyword(s) are deleted.
   */
  deleteContextual(versionId: string, planId: string, contextual: Words) {
    const command: DeletePlanContextualKeywords<Words> =
      new DeletePlanContextualKeywords(
        this.apiService,
        this.anyAdapter,
        planId,
        versionId,
        this.tenantId,
      );
    command.parameters = {};
    if (contextual?.id && !contextual?.isParent) {
      command.parameters['data'] = {
        contextualId: contextual.id,
        stateSessionId: this.store.getStateSession(),
      };
    } else if (contextual?.id && contextual?.isParent) {
      command.parameters['data'] = {
        contextualId: contextual.parentId,
        isParent: contextual.isParent,
        stateSessionId: this.store.getStateSession(),
      };
    } else {
      command.parameters['data'] = {
        stateSessionId: this.store.getStateSession(),
      };
      command.parameters['query'] = new HttpParams().set('deleteAll', true);
    }
    return command.execute();
  }
}
