import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {ReachData} from '../models/reach-data.model';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
import {REACH_DATA_TYPE} from '../../constants';
import {BudgetReachService} from '../services';
@Injectable()
export class BudgetReachSummaryAdapter implements IAdapter<ReachData> {
  strategies = Object.values(PlanBudgetStrategy).filter(key =>
    isNaN(Number(key)),
  );
  reachDataType = REACH_DATA_TYPE;
  constructor(private readonly budgetReachService: BudgetReachService) {}
  /**
   * The function `adaptToModel` iterates through strategies and updates chart flags in the ReachData
   * object before returning it.
   * @param {ReachData} resp - The `resp` parameter is of type `ReachData`, which is the data object
   * being passed into the `adaptToModel` function.
   * @returns The `adaptToModel` function is returning the `resp` object after updating its chart flags
   * based on the strategies defined in the `this.strategies` array.
   */
  public adaptToModel(resp: ReachData): ReachData {
    this.strategies.forEach(strategy => {
      this.budgetReachService.updateChartFlags(resp[strategy]);
    });
    return resp;
  }

  /**
   * The function `adaptFromModel` takes in a `ReachData` object and returns it as is.
   * @param {ReachData} data - The `adaptFromModel` function takes in a parameter `data` of type
   * `ReachData` and simply returns the same data without any modifications. If you need further
   * assistance or have any specific requirements for adapting the data, feel free to ask!
   * @returns The `adaptFromModel` function is returning the `data` parameter as is, without any
   * modifications.
   */
  adaptFromModel(data: ReachData): any {
    return data;
  }
}
