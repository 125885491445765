<div
  *ngIf="isParentPlanVersion"
  class="logo-img flex justify-center items-center"
  [matTooltip]="statusText"
>
  <i *ngIf="iconClass" [ngClass]="iconClass"></i>
  <img
    class="icon-image"
    *ngIf="imagePath"
    [src]="imagePath"
    [alt]="statusText"
  />
</div>
