import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSessionStoreService} from '@simplifi/core/store';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';

@Component({
  selector: 'simplifi-plan-name-cell-renderer',
  templateUrl: './plan-name-cell-renderer.component.html',
  styleUrl: './plan-name-cell-renderer.component.scss',
})
export class PlanNameCellRendererComponent implements ICellRendererComponent {
  constructor(
    private router: Router,
    protected readonly route: ActivatedRoute,
    private readonly store: UserSessionStoreService,
  ) {}
  planName = '';
  planId = '';
  planVersionId = '';
  enableNavigation = false;
  currentPage = 'general';
  versionTag = '';
  agInit(params: ICellRenderer): void {
    this.currentPage = params.data.currentPage;
    this.planName = params?.data?.name;
    this.planId = params?.data?.id;
    this.planVersionId = params?.data?.versionId;
    this.enableNavigation = params?.data?.child?.length === 1;
    this.versionTag = params?.data?.versionTag
      ? `_${params?.data?.versionTag}`
      : '';
  }

  refresh() {
    return true;
  }

  navigatePlanner() {
    this.router.navigate(
      [`./create/${this.currentPage}/${this.planId}/${this.planVersionId}`],
      {relativeTo: this.route},
    );
    this.store.setDefaultChatMessagesCheck(true, this.planId);
  }
}
