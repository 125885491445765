import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const requiredPermissions = route.data['permissions']?.only || [];
    const redirectTo = route.data['permissions']?.redirectTo || '/unauthorized';

    try {
      const hasPermission =
        await this.permissionsService.hasPermission(requiredPermissions);

      if (!hasPermission) {
        this.router.navigate([redirectTo]);
      }

      return hasPermission;
    } catch (error) {
      this.router.navigate([redirectTo]);
      return false;
    }
  }
}
