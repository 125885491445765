<div *ngIf="enableMenu" class="menu-container">
  <button class="text-right" [matMenuTriggerFor]="menu">
    <i class="el-icon-more rotate-90"></i>
  </button>
  <mat-menu class="btn-menu" #menu="matMenu" [xPosition]="xPosition">
    <button
      [ngClass]="option?.iconClass"
      (click)="onAction(option.value)"
      *ngFor="let option of menuOptions"
      mat-menu-item
    >
      <i [class]="option?.iconName"></i>
      <span>{{ option?.name }}</span>
    </button>
  </mat-menu>
</div>
