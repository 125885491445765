import {Inject, Injectable} from '@angular/core';
import {
  Budget,
  BudgetWidget,
  GeneralWidget,
  PlanNavigation,
  Suggestions,
  TargetWidget,
  WidgetNavigation,
} from '../models';
import {PlanView} from '@simplifi/shared/models';
import {LocationType, PlanBudgetStrategy} from '@simplifi/shared/enums';
import {CreatePlanService} from './create-plan.service';
import {WidgetStepper} from '../components/create-plan-widget-stepper';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '@simplifi/shared/components';
import {Messages} from '@simplifi/shared/constants';
import {Router} from '@angular/router';
import {MandatoryFields, Stepper} from '../enums';
import {IToaster, TOASTER_SERVICE_KEY} from '@simplifi/core/toaster';
import _ from 'lodash';
import {BudgetTypes} from '../models/budget-types.model';
import {Subject} from 'rxjs';

@Injectable()
export class PlanNavigationService {
  private validateFormSubject = new Subject();
  validateForm$ = this.validateFormSubject.asObservable();
  constructor(
    private readonly createPlanService: CreatePlanService,
    public readonly dialog: MatDialog,
    private readonly router: Router,
    @Inject(TOASTER_SERVICE_KEY)
    private readonly toaster: IToaster,
  ) {}
  originalPlanClone = new PlanView();
  planNavigation: PlanNavigation = new PlanNavigation();
  budgetClone = new BudgetTypes();

  /**
   * Discards unsaved changes in the plan based on the current active route in the stepper.
   * This method reverts changes made to different sections of the plan (General, Target, or Budget) by
   * restoring the original state from a deep clone of the original plan (`originalPlanClone`) or budget (`budgetClone`).
   * Depending on the active route (`activePlanRoute`), it calls specific methods to reset the widgets
   * and reinitialize them with their original values.
   * @param {string} activePlanRoute - The current active route in the stepper, representing which section of the plan is being edited.
   * @remarks
   * - If `activePlanRoute` corresponds to `Stepper.GENERAL`, the `resetGeneralWidget` method is called to discard changes in the general section.
   * - If `activePlanRoute` corresponds to `Stepper.TARGET`, the keywords, ZTV, contextual, and addressable words are reset, and the `TargetWidget` is reinitialized.
   * - If `activePlanRoute` corresponds to `Stepper.BUDGET`, the budget is reset using the `setBudget` method, and the `BudgetWidget` is reinitialized.
   * - If the `activePlanRoute` does not match any predefined cases, no changes are made.
   */
  discardPlanUnSavedChanges(activePlanRoute: string) {
    switch (activePlanRoute) {
      case Stepper.GENERAL:
        this.resetGeneralWidget();
        break;
      case Stepper.TARGET: {
        const originalPlan = _.cloneDeep(this.originalPlanClone);
        this.createPlanService.plan.keyword.words = originalPlan.keyword.words;
        this.createPlanService.plan.ztv.words = originalPlan.ztv.words;
        this.createPlanService.plan.contextual.words =
          originalPlan.contextual.words;
        this.createPlanService.plan.addressable.words =
          originalPlan.addressable.words;
        this.planNavigation.target = new TargetWidget();
        break;
      }
      case Stepper.BUDGET:
        this.createPlanService.setBudget(_.cloneDeep(this.budgetClone));
        this.planNavigation.budget = new BudgetWidget();
        break;
      default:
        break;
    }
  }

  /**
   * Resets the general widget in the plan by restoring its properties to the original values.
   * This method reverts the `createPlanService.plan` object to its initial state
   * based on a deep clone of the original plan (`originalPlanClone`).
   * It resets various properties including the plan's name, start and end dates,
   * advertiser URL, campaign goal ID and name, budget type and amount, location type,
   * content categories, and their corresponding names.
   * Additionally, it reinitializes the `general` property of `planNavigation` with a new `GeneralWidget` instance.
   * @remarks
   * - If the `originalPlan.locationType` is undefined or null, the `locationType` will be set to `LocationType.Metro`.
   * - This method is useful for reverting any changes made to the plan during the creation or editing process.
   */
  resetGeneralWidget() {
    const originalPlan = _.cloneDeep(this.originalPlanClone);
    this.createPlanService.plan.name = originalPlan.name;
    this.createPlanService.plan.startDate = originalPlan.startDate;
    this.createPlanService.plan.endDate = originalPlan.endDate;
    this.createPlanService.plan.advertiserUrl = originalPlan.advertiserUrl;
    this.createPlanService.plan.campaignGoalId = originalPlan.campaignGoalId;
    this.createPlanService.plan.campaignGoalName =
      originalPlan.campaignGoalName;
    this.createPlanService.plan.budgetType = originalPlan.budgetType;
    this.createPlanService.plan.budget[PlanBudgetStrategy.Max].amount =
      originalPlan.budget[PlanBudgetStrategy.Max].amount;
    this.createPlanService.plan.locationType =
      originalPlan.locationType ?? LocationType.Metro;
    this.createPlanService.plan.contentCategories =
      originalPlan.contentCategories;
    this.createPlanService.plan.contentCategoriesName =
      originalPlan.contentCategoriesName;
    this.planNavigation.general = new GeneralWidget();
  }

  /**
   * The function `planWidgetNavUnsavedChangesExist` checks if there are unsaved changes in the active plan's
   * navigation widget, depending on the type of the active plan route.
   * @param {string} activePlanRoute - The `activePlanRoute` parameter is a string that represents the
   * current route in the plan navigation. It can be 'general', 'target', or 'budget'.
   * @returns {boolean[]} - The function returns an array of boolean values indicating whether there are
   * unsaved changes in the active plan's widget. The first boolean represents if changes exist, while the second
   * typically indicates other relevant status, such as whether a save action is necessary.
   * The function operates as follows:
   * - If the `activePlanRoute` is `Stepper.GENERAL`, it checks the general widget using the `checkGeneralWidget`
   *   method and returns the result.
   * - If the `activePlanRoute` is `Stepper.TARGET`, it checks the target widget using the `checkTargetWidget`
   *   method and returns the result.
   * - If the `activePlanRoute` is `Stepper.BUDGET`, it checks the budget widget using the `checkBudgetWidget`
   *   method and returns the result.
   * - If the `activePlanRoute` does not match any of these, it returns `[true, false]` as a default.
   */
  planWidgetNavUnsavedChangesExist(activePlanRoute: string): boolean[] {
    const activePlanRouteNav =
      this.planNavigation[activePlanRoute as 'general' | 'target' | 'budget'];

    if (activePlanRoute === Stepper.GENERAL) {
      const {formSection, location} = activePlanRouteNav as GeneralWidget;
      return this.checkGeneralWidget(formSection, location);
    }
    if (activePlanRoute === Stepper.TARGET) {
      return this.checkTargetWidget(activePlanRouteNav as TargetWidget);
    }
    if (activePlanRoute === Stepper.BUDGET) {
      const {budget, suggestedBudget} = activePlanRouteNav as BudgetWidget;
      return this.checkBudgetWidget(budget, suggestedBudget);
    }
    return [true, false];
  }

  /**
   * The function `checkGeneralWidget` checks the validity and unsaved status of two widget navigation
   * sections.
   * @param {WidgetNavigation} formSection - The `formSection` parameter is a widget that represents a
   * form section in the user interface.
   * @param {WidgetNavigation} location - The `location` parameter in the `checkGeneralWidget` function
   * is of type `WidgetNavigation`.
   * @returns An array of boolean values is being returned. The first element in the array represents
   * the logical AND of the `formValid` properties of `formSection` and `location`. The second element
   * in the array represents the logical OR of the `unsave` properties of `formSection` and `location`.
   */
  private checkGeneralWidget(
    formSection: WidgetNavigation,
    location: WidgetNavigation,
  ): boolean[] {
    return [
      formSection.formValid && location.formValid,
      formSection.unsave || location.unsave,
    ];
  }

  /**
   * The function `checkTargetWidget` iterates over a given object to check for specific properties and
   * returns an array indicating if certain conditions are met.
   * @param {TargetWidget} targetWidget - The `targetWidget` parameter is an object of type
   * `TargetWidget`, which contains properties representing different navigation elements. The function
   * `checkTargetWidget` iterates over these properties to check if any of the navigation elements have
   * `formValid` set to true or `unsave` set to true.
   * @returns The function `checkTargetWidget` is returning an array with two boolean values: `formValid`
   * and `unsaveData`.
   */
  private checkTargetWidget(targetWidget: TargetWidget): boolean[] {
    let formValid = false;
    let unsaveData = false;

    for (const key in targetWidget) {
      if (Object.hasOwn(targetWidget, key)) {
        const navElement = targetWidget[key as keyof TargetWidget];
        if (navElement.formValid) {
          formValid = true;
        }
        if (navElement.unsave) {
          unsaveData = true;
        }
      }
    }
    return [formValid, unsaveData];
  }

  /**
   * The function `checkBudgetWidget` compares the form validity and save status of two
   * WidgetNavigation objects and returns an array of boolean values.
   * @param {WidgetNavigation} budget - The `budget` parameter represents the budget information for a
   * widget navigation.
   * @param {WidgetNavigation} suggestedBudget - The `suggestedBudget` parameter in the
   * `checkBudgetWidget` function is of type `WidgetNavigation`. It likely contains information related
   * to a suggested budget for a widget, such as form validity and save status.
   * @returns An array of boolean values is being returned. The first element in the array represents
   * whether both the `budget` and `suggestedBudget` have valid forms, while the second element
   * represents whether either the `budget` or `suggestedBudget` is marked as unsaved.
   */
  private checkBudgetWidget(
    budget: WidgetNavigation,
    suggestedBudget: WidgetNavigation,
  ): boolean[] {
    return [
      budget.formValid && suggestedBudget.formValid,
      budget.unsave || suggestedBudget.unsave,
    ];
  }

  /**
   * The function `openConfirmationDialog` displays a confirmation dialog with a message and handles
   * different actions based on user input.
   * @param {string} message - The `message` parameter in the `openConfirmationDialog` function is a
   * string that represents the message or question that will be displayed in the confirmation dialog
   * box. It is the text that prompts the user to confirm or cancel an action.
   * @param {string} url - The `url` parameter in the `openConfirmationDialog` function is a string that
   * represents the URL or web address associated with the action being confirmed. It is used to
   * determine the destination or target of the action based on the user's confirmation choice.
   * @param {boolean} save - The `save` parameter in the `openConfirmationDialog` function is a boolean
   * value that indicates whether the user wants to save the changes or not. If `save` is `true`, it
   * means the user wants to save the changes, and if it's `false`, it means the user does
   * @param {Stepper} stepper - The `stepper` parameter in the `openConfirmationDialog` function is of
   * type `Stepper`. It is used as an input to navigate to a specific step within a stepper component.
   * The `stepper` parameter likely contains information about the current step or the step to navigate
   * to within the stepper
   */
  openConfirmationDialog(
    message: string,
    url: string,
    save: boolean,
    stepper: Stepper,
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '450px',
      width: '100%',
      data: {
        confirmationMessage: message,
        actionNoLabel: 'No',
        actionYesLabel: 'Yes',
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm === undefined) {
        return;
      }
      if (save) {
        if (confirm) {
          this.createPlanService.setSavePlanObv(url);
        } else {
          this.discardAndNavigate(url, stepper);
        }
      } else if (!save && confirm) {
        this.discardAndNavigate(url, stepper);
      } else {
        //else
      }
    });
  }

  /**
   * The function `discardAndNavigate` checks if a URL is allowed for navigation, discards unsaved
   * changes if necessary, and then navigates to the URL.
   * @param {string} url - The `url` parameter is a string that represents the URL to navigate to.
   * @param {Stepper} stepper - The `stepper` parameter in the `discardAndNavigate` function is used to
   * determine the type of stepper being used. It is likely an enum or a string that represents a
   * specific type of stepper component in the application. The function then uses this information to
   * access relevant data from the `WidgetSte
   */
  discardAndNavigate(url: string, stepper: Stepper) {
    const widgetData = WidgetStepper[stepper];
    if (widgetData.allForwordRoutes.some(ele => url.includes(ele))) {
      if (this.checkIfCanNavigateForward(url, stepper)) {
        this.discardPlanUnSavedChanges(stepper);
        this.router.navigate([url]);
      } else {
        this.toaster.warn(Messages.unsaveWarning);
      }
    } else {
      this.discardPlanUnSavedChanges(stepper);
      this.router.navigate([url]);
    }
  }

  /**
   * The function `checkIfCanNavigateForward` determines if navigation to the next step is allowed
   * based on the current step in a stepper component.
   * @param {string} url - It looks like you have provided a code snippet for a function called
   * `checkIfCanNavigateForward` that takes in a URL and a `Stepper` enum as parameters. The function
   * checks different conditions based on the value of the `stepper` parameter to determine if
   * navigation forward is allowed.
   * @param {Stepper} stepper - The `checkIfCanNavigateForward` function takes in two parameters: `url`
   * of type string and `stepper` of type `Stepper`. The function then checks the value of `stepper`
   * and performs different validation checks based on the value.
   * @returns The `checkIfCanNavigateForward` function is checking different conditions based on the
   * `stepper` parameter and returning a boolean value accordingly. Here is what is being returned for
   * each case:
   */
  checkIfCanNavigateForward(url: string, stepper: Stepper) {
    switch (stepper) {
      case Stepper.GENERAL: {
        const generalValid = MandatoryFields.every(
          field =>
            (this.originalPlanClone[field] as [])?.length ||
            this.originalPlanClone[field],
        );
        const locationValid = !!this.originalPlanClone.locations?.length;
        return generalValid && locationValid;
      }
      case Stepper.TARGET:
        return this.checkTargetsValidity();
      case Stepper.BUDGET:
        return (
          Number(this.budgetClone[PlanBudgetStrategy.Max].total) ===
            this.budgetClone[PlanBudgetStrategy.Max].allocated &&
          this.budgetClone[PlanBudgetStrategy.Max].edit
        );
      default:
        return false;
    }
  }

  /**
   * The function `checkTargetsValidity` checks if any of the specified targets have non-empty word
   * suggestions in the original plan clone.
   * @returns The `checkTargetsValidity()` function is returning a boolean value. It checks if any of
   * the specified keys ('keyword', 'ztv', 'contextual', 'addressable') in the `originalPlanClone`
   * object have a property named 'words' with a length greater than zero in the `Suggestions` object.
   * If at least one of these conditions is met, the function returns `true`;
   */
  checkTargetsValidity() {
    return ['keyword', 'ztv', 'contextual', 'addressable'].some(
      ele =>
        !!(this.originalPlanClone[ele as keyof PlanView] as Suggestions)?.words
          ?.length,
    );
  }

  /**
   * The function `handlePlanNavigation` determines whether to save changes or discard them based on
   * form validity and URL, and opens a confirmation dialog accordingly.
   * @param {string} url - The `url` parameter is a string that represents the URL of the page or
   * resource being navigated to.
   * @param {boolean} formValid - The `formValid` parameter is a boolean value that indicates whether
   * the form data is valid or not.
   * @param {Stepper} stepper - The `stepper` parameter in the `handlePlanNavigation` function is
   * likely an object that helps manage the navigation steps within a multi-step process or form. It
   * could contain information about the current step, the total number of steps, and methods to
   * navigate between steps.
   */
  handlePlanNavigation(url: string, formValid: boolean, stepper: Stepper) {
    let confirmationMessage = Messages.unsavedChanges;
    let save = true;
    if (!formValid) {
      save = false;
      confirmationMessage = Messages.discardChanges;
    }
    this.openConfirmationDialog(confirmationMessage, url, save, stepper);
  }

  /**
   * The function `markFormAsSaved` updates the `unsave` property of form sections within different
   * steppers to indicate that the form has been saved.
   * @param {Stepper} stepper - The `stepper` parameter in the `markFormAsSaved` function is used to
   * determine which section of a form has been saved. The function then updates the `unsave` property
   * of various fields within that section to indicate that the form has been saved. The `stepper`
   * parameter is
   */
  markFormAsSaved(stepper: Stepper) {
    switch (stepper) {
      case Stepper.GENERAL:
        this.planNavigation.general.formSection.unsave = false;
        this.planNavigation.general.location.unsave = false;
        break;
      case Stepper.TARGET:
        for (const key in this.planNavigation[stepper]) {
          if (Object.hasOwn(this.planNavigation[stepper], key)) {
            this.planNavigation.target[key as keyof TargetWidget].unsave =
              false;
          }
        }
        break;
      case Stepper.BUDGET:
        this.planNavigation.budget.budget.unsave = false;
        this.planNavigation.budget.suggestedBudget.unsave = false;
        break;
      default:
    }
  }
  triggerFormValidation() {
    this.validateFormSubject.next(null);
  }
}
