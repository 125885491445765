import {PlanStatus} from '@simplifi/shared/enums';

import {
  DonutDataset,
  OverAllTotals,
  Percentages,
  PlanResultTactics,
  PlanResultZtvSummaryData,
} from '.';
import {ResultTiles} from './result-tiles.model';

export class Result {
  resultTiles!: ResultTiles;
  tacticsData: PlanResultTactics;
  donutDatasets: DonutDataset;
  donutPercentage!: string;
  donutPercentageKey!: string;
  rowData: PlanResultZtvSummaryData[];
  status: PlanStatus;
  constructor() {
    this.donutDatasets = new DonutDataset();
    this.status = PlanStatus.Complete;
    this.rowData = [];
    this.tacticsData = new PlanResultTactics({
      metrics: {},
      percentages: new Percentages(),
      totals: new OverAllTotals(),
    });
  }
}
