import {MatSnackBarConfig} from '@angular/material/snack-bar';
import {Messages} from '@simplifi/shared/constants';

const panelClass = 'snackbar-download';

export const BANNER_CONFIG: MatSnackBarConfig = {
  data: {
    message:
      'Goal type/tiers/people reach/household reach/frequency cap of the budget/percentage of the specific tier has been updated by the super admin panel',
  },
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass,
};

export const BUDGET_MODE_PERCENTAGE_CONFIG: MatSnackBarConfig = {
  data: {
    message:
      'Due to the rounding of percentage values, the total budget may differ from the actual budget.',
  },
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass,
};

export const RECALCULATED_BUDGET_BANNER: MatSnackBarConfig = {
  data: {
    message:
      'Your updated budget falls under a new tier based on your goal configuration. Navigate to the target page to view the updated targets.',
  },
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass,
};

export const CONNECTION_LOST_BANNER: MatSnackBarConfig = {
  data: {
    message: Messages.noNetworkMessage,
  },
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass,
};

export const SHOW_TOOLTIP_FIELDS = ['Current'];
export const SHOW_LABEL_FIELDS = ['Current'];
export const REACH_DATA_TYPE = ['household', 'population'];
