import {Component} from '@angular/core';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';

@Component({
  selector: 'simplifi-plan-goal-cell-renderer',
  templateUrl: './plan-goal-cell-renderer.component.html',
  styleUrl: './plan-goal-cell-renderer.component.scss',
})
export class PlanGoalCellRendererComponent implements ICellRendererComponent {
  constructor() {}
  goalName = '';
  agInit(params: ICellRenderer): void {
    this.goalName = params?.data?.goalName;
  }

  refresh() {
    return true;
  }
}
