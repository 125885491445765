import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PATTERNS} from '@simplifi/shared/constants';
import {
  FilterOperator,
  budgetFilterOptionsValues,
  dateFilterOptionsValues,
  goalFilterOptionsValues,
  planStatusFilterOptions,
  targetFilterOptionsValues,
} from '@simplifi/shared/enums';
import {FilterDropdown} from '@simplifi/shared/interfaces';
import {OperatorFilter} from '@simplifi/shared/models/operator-filter.model';
import {PlanFilter} from '@simplifi/shared/models/plan-filter.model';
import {cloneDeep, isEmpty} from 'lodash';
import {CampaignGoal} from '../../create-plan/models';
import {PlanFacadeService} from '../../services';
import {take} from 'rxjs';
import dayjs from 'dayjs'; // added Dayjs because date picker uses this format instead of javascript Date
import moment from 'moment';

@Component({
  selector: 'simplifi-data-filter',
  templateUrl: './data-filter.component.html',
  styleUrl: './data-filter.component.scss',
})
export class DataFilterComponent implements OnInit {
  tempPlanFilter = new PlanFilter();
  @Input() planFilter!: PlanFilter;
  @Output() planFilterChange = new EventEmitter();
  @Output() filterCountUpdate = new EventEmitter();
  filterCount = 0;
  selectedDate!: {startDate: Date; endDate: Date};
  statusOptions: FilterDropdown[] = planStatusFilterOptions;
  budgetOptions: FilterDropdown[] = budgetFilterOptionsValues;
  dateOptions: FilterDropdown[] = dateFilterOptionsValues;
  goalOptions: FilterDropdown[] = goalFilterOptionsValues;
  goal: CampaignGoal[] = [];
  targetOptions: FilterDropdown[] = targetFilterOptionsValues;

  pattern = PATTERNS;
  budgetFilter: {startingBudget: number; endingBudget: number} = {
    startingBudget: 0,
    endingBudget: 0,
  };
  between = FilterOperator.BETWEEN;
  constructor(private readonly planFacadeService: PlanFacadeService) {}
  ngOnInit(): void {
    this.tempPlanFilter = cloneDeep(this.planFilter) ?? new PlanFilter();
    this.planFacadeService
      .getCampaignGoalData(false, false, 0, 0, '', true)
      .pipe(take(1))
      .subscribe(data => {
        this.goal = data;
      });

    if (this.tempPlanFilter.versionCreatedOn?.value) {
      this.selectedDate = {} as {startDate: Date; endDate: Date};
      if (
        this.tempPlanFilter.versionCreatedOn.operator ===
          FilterOperator.BETWEEN ||
        this.tempPlanFilter.versionCreatedOn.operator === FilterOperator.EQ
      ) {
        this.selectedDate.startDate = new Date(
          (this.tempPlanFilter.versionCreatedOn.value as string[])[0],
        );
        this.selectedDate.endDate = moment(
          (this.tempPlanFilter.versionCreatedOn.value as string[])[1],
        ).toDate();
      } else {
        this.selectedDate.startDate = new Date(
          this.tempPlanFilter.versionCreatedOn.value as string,
        );
      }
    }

    if (this.tempPlanFilter.totalBudget?.value) {
      if (this.tempPlanFilter.totalBudget.operator === FilterOperator.BETWEEN) {
        this.budgetFilter.startingBudget = (
          this.tempPlanFilter.totalBudget.value as number[]
        )[0];
        this.budgetFilter.endingBudget = (
          this.tempPlanFilter.totalBudget.value as number[]
        )[1];
      } else {
        this.budgetFilter.startingBudget = this.tempPlanFilter.totalBudget
          .value as number;
      }
    }
  }

  updateFilterCount() {
    this.filterCount = 0;
    if (this.selectedDate?.startDate) {
      if (
        this.tempPlanFilter.versionCreatedOn.operator ===
          FilterOperator.BETWEEN ||
        this.tempPlanFilter.versionCreatedOn.operator === FilterOperator.EQ
      ) {
        this.tempPlanFilter.versionCreatedOn.value = [
          this.getUtcTime(this.selectedDate.startDate),
        ];
        this.tempPlanFilter.versionCreatedOn.value.push(this.calculateEndDay());
      } else {
        this.tempPlanFilter.versionCreatedOn.value = this.getUtcTime(
          this.selectedDate.startDate,
          this.tempPlanFilter.versionCreatedOn.operator === FilterOperator.GT
            ? 1
            : 0,
        );
      }
    }

    this.checkBudget();

    if (!this.tempPlanFilter.versionCreatedOn.value) {
      this.tempPlanFilter.versionCreatedOn = {} as OperatorFilter;
    }

    Object.values(this.tempPlanFilter).forEach(value => {
      if (!isEmpty(value)) {
        this.filterCount++;
      }
    });
    this.filterCountUpdate.emit(this.filterCount);
  }

  calculateEndDay() {
    return this.tempPlanFilter.versionCreatedOn.operator === FilterOperator.EQ
      ? this.getUtcTime(this.selectedDate.startDate, 1)
      : this.getUtcTime(this.selectedDate.endDate);
  }

  getUtcTime(date: Date, add = 0) {
    return dayjs(date)
      .add(add, 'day')
      .subtract(dayjs().utcOffset(), 'minutes')
      .toISOString();
  }

  checkBudget() {
    if (this.budgetFilter.startingBudget) {
      if (this.tempPlanFilter.totalBudget.operator === FilterOperator.BETWEEN) {
        this.tempPlanFilter.totalBudget.value = [
          this.budgetFilter.startingBudget,
          this.budgetFilter.endingBudget,
        ];
      } else {
        this.tempPlanFilter.totalBudget.value =
          this.budgetFilter.startingBudget;
      }
    }
  }

  applyFilters() {
    if (!this.filterCount) {
      this.clearFilters();
    } else {
      this.planFilter = cloneDeep(this.tempPlanFilter);
      this.planFilterChange.emit(this.planFilter);
      this.updateFilterCount();
    }
  }

  clearFilters() {
    this.tempPlanFilter = new PlanFilter();
    this.selectedDate = {} as {startDate: Date; endDate: Date};
    this.planFilter = cloneDeep(this.tempPlanFilter);
    this.budgetFilter.startingBudget = 0;
    this.budgetFilter.endingBudget = 0;
    this.planFilterChange.emit(this.planFilter);
    this.updateFilterCount();
  }

  updateDates() {
    if (
      !(
        this.tempPlanFilter.versionCreatedOn.operator ===
          FilterOperator.BETWEEN ||
        this.tempPlanFilter.versionCreatedOn.operator === FilterOperator.EQ
      ) &&
      this.selectedDate.startDate
    ) {
      this.tempPlanFilter.versionCreatedOn.value = this.getUtcTime(
        this.selectedDate.startDate,
      );
    }
  }
}
