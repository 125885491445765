import {Component, Input} from '@angular/core';
import {PlanCopyDto} from '@simplifi/main/planner/create-plan/models';
import {
  PlanBudgetStrategy,
  PlanDeleteOption,
  PlanStatus,
} from '@simplifi/shared/enums';
import {InputButtonDropdown} from '@simplifi/shared/interfaces';
import {ICellRenderer, ICellRendererComponent} from '@simplifi/shared/modules';
import {
  CellClickService,
  CopyHelperService,
  DeleteHelperService,
} from '@simplifi/shared/services';
import {SelectBoxDropwdownValue} from '@simplifi/shared/types';

export enum PlanListActions {
  delete = 'Delete',
  copy = 'Copy Plan',
  downloadSuggested = 'Download Plan (Suggested Budget)',
  downloadPlanBudget = 'Download Plan (Plan Budget)',
  downloadMax = 'Download Plan (Max Budget)',
}

@Component({
  selector: 'simplifi-plan-context-menu-renderer',
  templateUrl: './plan-context-menu-renderer.component.html',
  styleUrl: './plan-context-menu-renderer.component.scss',
})
export class PlanContextMenuRendererComponent
  implements ICellRendererComponent
{
  @Input() xPosition: 'before' | 'after' = 'before';

  menuOptions: InputButtonDropdown[] = [
    {
      value: 'copy',
      name: PlanListActions.copy,
      iconName: 'el-icon-copy-small',
      iconClass: 'el-link',
    },
    {
      value: PlanDeleteOption.Delete,
      name: PlanListActions.delete,
      iconName: 'el-icon-delete-small',
      iconClass: 'el-link el-link--danger',
    },
  ];

  planId!: string;
  planCopyData = new PlanCopyDto();
  planName = '';
  planStatus = '';
  enableMenu = false;
  aiSuggested = false;

  constructor(
    private readonly deleteHelper: DeleteHelperService,
    private readonly copyHelper: CopyHelperService,
    private readonly planStatusService: CellClickService,
  ) {}

  agInit(params: ICellRenderer): void {
    this.initializeParams(params);
    this.updateMenuOptions();
  }

  private initializeParams(params: ICellRenderer): void {
    const data = params?.data;

    this.planName = data?.name;
    this.planStatus = data?.status;
    this.planId = data?.id;
    this.aiSuggested = data?.budget.aiSuggested;

    this.planCopyData.id = data?.id;
    this.planCopyData.versionId = data?.versionId;
    this.planCopyData.isPlanCopyOperation = true;
    this.planCopyData.name = this.planName;

    this.enableMenu = data?.child?.length === 1;
  }

  private updateMenuOptions(): void {
    if (this.isDownloadable()) {
      this.addDownloadOptions();
    }
  }

  private isDownloadable(): boolean {
    return (
      this.planStatus === PlanStatus.Complete ||
      this.planStatus === PlanStatus.Downloaded
    );
  }

  private addDownloadOptions(): void {
    this.menuOptions.splice(
      1,
      0,
      {
        value: `${PlanStatus.Downloaded}-${PlanBudgetStrategy.Suggested}`,
        name: this.aiSuggested
          ? PlanListActions.downloadSuggested
          : PlanListActions.downloadPlanBudget,
        iconName: 'el-icon-download-small',
        iconClass: 'el-link',
      },
      {
        value: `${PlanStatus.Downloaded}-${PlanBudgetStrategy.Max}`,
        name: PlanListActions.downloadMax,
        iconName: 'el-icon-download-small',
        iconClass: 'el-link',
      },
    );
  }

  onAction(action?: SelectBoxDropwdownValue) {
    if (!action) return;

    const handlers: {[key: string]: () => void} = this.getActionHandlers();
    const actionHandler = handlers[action] || handlers['default'];

    actionHandler();
  }

  private getActionHandlers(): {[key: string]: () => void} {
    return {
      [PlanDeleteOption.Delete]: () =>
        this.deleteHelper.deleteById(this.planId),
      [`${PlanStatus.Downloaded}-${PlanBudgetStrategy.Suggested}`]: () =>
        this.handleDownload(PlanBudgetStrategy.Suggested),
      [`${PlanStatus.Downloaded}-${PlanBudgetStrategy.Max}`]: () =>
        this.handleDownload(PlanBudgetStrategy.Max),
      default: () => this.copyHelper.copyById(this.planCopyData),
    };
  }

  private handleDownload(budgetStrategy: PlanBudgetStrategy) {
    if (this.isDownloadable()) {
      this.planStatusService.setData({planId: this.planId});
      this.downloadPlan(budgetStrategy);
    }
  }

  private downloadPlan(budgetStrategy: PlanBudgetStrategy): void {
    window.open(
      `/main/planner/exports/${this.planCopyData.id}/${this.planCopyData.versionId}?strategy=${budgetStrategy}`,
      '_blank',
    );
  }

  refresh(): boolean {
    return true;
  }
}
